@import "../../styles/app_variables";

.ui-collapsible-panel.MuiPaper-root.MuiExpansionPanel-root {
	&:first-of-type,
	&.ui-collapsible-first.Mui-expanded {
		margin-top: 0px !important;
	}
	&:before {
		display: none !important;
	}
	&.Mui-disabled {
		background-color: rgba(0, 0, 0, 0.09);
		cursor: not-allowed;
	}
	.MuiCollapse-container,
	.MuiExpansionPanelDetails-root {
		display: block !important;
	}
}

.MuiButtonBase-root.MuiExpansionPanelSummary-root.Mui-expanded {
	// border-bottom: dotted 1px $gray-300;
	border-bottom: solid 1px $gray-100;
}

.ui-no-data {
	font-weight: normal !important;
	font-size: $font-size-md;
}

ul.ui-data-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	text-align: left;
	li {
		width: auto;
		float: left;
		margin-right: 4px;
		.comma {
			font-size: $font-size-xxs;
			color: $gray-600;
		}
	}
}

.ui-el-circle-loader {
	&.ui-el-circle-loader-processing {
		cursor: not-allowed !important;
		pointer-events: unset;
		.ui-el-circle-loader-content,
		.ui-el-circle-loader-content * {
			color: $gray-500 !important;
		}
	}
}
