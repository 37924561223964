@import "app_variables";
@import "~bootstrap";
@import "~bootstrap/scss/bootstrap";
@import "layout_utils";
@import "text";
@import "forms";
@import "ios-overrides";
@import "table";
// @import "primeng_override";
// @import "./toastr.scss"; // https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.css
@import "./animate.css";
@import "./iconmonstr-iconic-font-1.3.0/css/iconmonstr-iconic-font.css";
//@import url("https://fonts.googleapis.com/css?family=Crimson+Text|Montserrat:wght@500;600;700|Shadows+Into+Light");
//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500&family=Baloo+Paaji+2&family=Candal&family=Capriola&family=Coda&family=Darker+Grotesque&family=Fahkwang&family=Fredoka&family=Georama&family=Grandstander&family=Hind+Guntur&family=IM+Fell+English+SC&family=Karla&family=Koulen&family=McLaren&family=Pompiere&family=Short+Stack&family=Skranji&family=Thasadith:wght@400;700&family=ZCOOL+XiaoWei&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Actor&family=Aldrich&family=Amatic+SC&family=Baloo+2&family=Candal&family=Covered+By+Your+Grace&family=Encode+Sans+Condensed&family=Gudea&family=Hind+Siliguri&family=Noto+Sans+Lao+Looped&family=Oxygen&family=PT+Sans+Caption&family=Quattrocento+Sans&family=Sofia+Sans+Semi+Condensed&family=Voltaire&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&family=Karla:ital,wght@0,200;0,300;0,400;0,500;1,400;1,500&display=swap');
@import "./font-awesome/css/all.css";
// @import "~primereact/resources/themes/nova-light/theme.css";
// @import "~primereact/resources/primereact.min.css";
// @import "~primeicons/primeicons.css";

:root {
	font-size: 16px; // set root to 16px to adjust rem definitions, removes iOS zoom focus
	--color-primary: #{$primary};
	--color-contrast-text: #{$light};
	--color-contrast-bg: #{$dark};
	--color-black-bg: #{$bg-black};
	--color-contrast-bg-hover: #{$light-outline-color};
	--color-nav-borders: #{$light-outline-color};
	--color-hr: rgba(255, 255, 255, 0.1);
	--color-footer-bg: #{$dark};
	--color-footer-text: #{$white};  // rgba(255, 255, 255, 0.8);
	--color-copyright: rgba(255, 255, 255, 0.6);
}

.light-mode {
	--color-contrast-text: #{$dark};
	--color-contrast-bg: #{$light};
	--color-black-bg: #{$light};
	--color-contrast-bg-hover: rgba(238, 238, 238, 0.6);
	--color-nav-borders: #{$gray-300};
	--color-hr: #{$gray-300};
	--color-footer-bg: #{$gray-100};
	--color-footer-text: #{$gray-600};
	--color-copyright: #{$gray-600};

	.text-white {
		color: $dark !important;
	}

	.bg-black {
		.text-white {
			color: $white !important;
		}
	}
}

body {
	margin: 0;
	padding: 0;
	@include os-scrollbars(0.6em, $gray, $gray-300); // $appLightBlack
}

html,
body,
div {
	scroll-behavior: smooth;
	@include os-scrollbars(0.6em, $gray, $gray-300);
}

// remove mobile yellow border when tapped
* {
	&:focus {
		outline-color: $primary; // groove // ridge 3px
		outline-style: solid; // ridge
		outline-width: 1px;
	}
}

// teach old browsers to treat HTML5 elements as block elements
header,
section,
footer,
aside,
nav,
main,
article,
figure {
	display: block;
}

// $os-scrollbar-radius: 0px;
// ::-webkit-scrollbar {
// 	width: 0.8rem;
// 	height: 0.5rem;
// }

// ::-webkit-scrollbar:hover {
// 	// background: $cor-fair-blue;
// 	// background-color: $primary;
// }

::-webkit-scrollbar-track {
	-webkit-border-radius: $os-scrollbar-radius;
	border-radius: $os-scrollbar-radius;
	// background: $gray; //rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: $os-scrollbar-radius;
	border-radius: $os-scrollbar-radius;
	// background: rgba(0, 0, 0, 0.2);
	// background-color: orange;
}

::-webkit-scrollbar-thumb:hover {
	// background: rgba(0, 0, 0, 0.4);
	// background-color: red;
	// background: orangered;
}

// ::-webkit-scrollbar-thumb:window-inactive {
// 	// background-color: rgba(0, 0, 0, 0.05);
// }

// boostrap overrides:
.container-fluid {
	padding: 0px; // ch149
}

// global definitions:

h1,
h2 {
	color: $black;
	font-family: $headings-font-family !important;
	text-align: left;
	margin-left: 0px;
	font-weight: 500;
}

h1 {
	font-size: 2.5rem;
	&.highlighted {
		@include h1Highlighted();
	}
	@include style-layout-mobile() {
		font-size: 2rem !important;
	}
}

h2 {
	font-size: 1.9rem; // 36px
	@include style-layout-mobile() {
		font-size: 1.7rem;
	}
}

h3 {
	font-size: 1rem; // 24px
	text-transform: uppercase;
	color: $gray-700;
	margin-bottom: 1.5rem;
	&.subheading {
		text-transform: none;
		font-size: 1.2rem;
		line-height: 1.3;
		margin-bottom: 0.7rem;
	}
}

h4 {
	text-transform: uppercase;
	color: $gray-700;
	margin-bottom: 1.5rem;
}

h5 {
	text-transform: uppercase;
	color: $gray-700;
	margin-bottom: 1.5rem;
}

hr {
	height: 1px;
	background-color: var(--color-hr);
	border: none;

	&.gray {
		background-color: $gray-500;
	}
}

a {
	cursor: pointer !important;
	* {
		cursor: pointer !important;
	}
	transition: color ease-out 0.1s;

	&.active {
		color: $link-color;
	}
	&.text-white {
		color: $white;
		*,
		h2 {
			color: $white !important;
		}
		&:hover {
			color: $link-hover-color !important;
			* {
				color: $link-hover-color !important;
			}
		}
	}
	&:hover {
		.mss-image-view {
			// background-color: rgba(15, 15, 15, 0.5);
			// transition: background-color 150ms ease-in;
		}
	}
}

p {
	line-height: 1.5;
}

.link {
	color: $link-color;
	cursor: pointer !important;
	&:hover,
	&:hover * {
		color: $link-hover-color !important; // TODO
	}
	&.underline {
		text-decoration: underline;
	}
}

.gray-link {
	color: darken($alternative, 30%);// $gray;
	cursor: pointer;
	&:hover,
	&:hover * {
		color: $link-color;
	}
	&.inner-link:not(:hover) * {
		color: $gray;
		&:hover {
			color: $link-color !important;
		}
	}
	&.inner-link * {
		text-decoration: none;
	}
	&.underline {
		text-decoration: underline;
	}
}

.highlight-link {
	color: $highlight;
	text-decoration: none;
	&.inner-link * {
		text-decoration: none;
	}
	i.im {
		color: $gray !important;
	}
}

.title-link {
	cursor: pointer !important;
	a {
		cursor: pointer !important;
		&:not(:hover) {
			color: $black;
		}
	}
}

.bg-gray {
	background-color: #f1f1f3 !important; // rgba(116, 121, 128, 0.05) // #EDDDD4
}

.bg-black {
	background-color: $dark;
}

.bg-alternative {
	background-color: $alternative;
}

.bg-app-black {
	background-color: $appLightBlack;
}

.bg-white-opac {
	background-color: rgba(255, 255, 255, 0.85);
}

.bg-dark-opac {
	background-color: rgba(23, 23, 56, 0.55);
}

i.im {
	font-size: $font-size-xs;
}

.site-editable {
	// cursor: url(../assets/icons/iconmonstr-pencil-6-16.png), text;
}

.heading {
	font-family: $headings-font-family !important;
}

.progress-bar {
	@include progress-bar-background();
	height: 26px;
	padding: 15px 50px;
	font-size: 13px;
}

.progress-text {
	@include progress-bar-background();
	width: auto;
	text-transform: none;
	cursor: not-allowed;
	color: $gray-600 !important;
	border: none 0px;

	*,
	&:hover,
	&:hover * {
		cursor: not-allowed;
		color: $gray-600 !important;
	}
}

.animation-delay-1s {
	animation-delay: 1s;
}

.animated.fast-1 {
	animation-duration: 0.2s;
}
.animated.fast-2 {
	animation-duration: 0.15s;
}

.text-danger {
	font-weight: bold;
	color: $danger;
}

// must set width
.ui-text-overflow {
	@include text-overflow();
}

p.ui-new-line-p {
	padding: 0px;
	margin: 0px;

	&:not(:last-of-type) {
		padding-bottom: 3px;
	}

	@include style-layout-mobile() {
		&:not(:last-of-type) {
			padding-bottom: 5px;
		}
	}
}

.section-padding {
	padding-top: 40px;
	padding-bottom: 60px;

	&.container,
	&.update-profile-container {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	@include style-layout-mobile() {
		// padding: 70px 15px;
		&.update-profile-container {
			padding-right: 15px;
			padding-left: 15px;
		}
	}
}

.white-side-left {
	@include style-layout-desktop() {
		&:before {
			content: "";
			display: inline-block;
			position: absolute;
			background-color: $white;
			left: 0px;
			top: 0px;
			bottom: 0px;
			@include viewport-unit(width, 5vw);
			height: 100%;
			z-index: 3;
		}
	}
}

.white-side-right {
	@include style-layout-desktop() {
		&:before {
			content: "";
			display: inline-block;
			position: absolute;
			background-color: $white;
			right: 0px;
			top: 0px;
			bottom: 0px;
			@include viewport-unit(width, 5vw);
			height: 100%;
			z-index: 3;
		}
	}
}

.ui-modal-content {
	@include viewport-unit(max-height, 86vh);
	overflow: auto;
	margin-top: -5px;
}

.ui-modal-close-icon {
	margin-top: -25px;
	margin-right: -15px;

	@include style-layout-mobile() {
		margin-top: -15px;
		margin-right: -25px;
	}
}

.loading-text {
	position: relative;
	padding-top: 2px;
	padding-right: 26px;
	color: $gray-500;
	&:hover {
		color: $gray-500 !important;
	}
	@include showLoader();
	&:before {
		width: 14px;
		height: 14px;
		margin-top: 0px;
		margin-left: 0px;
		top: 2px;
	}
	cursor: progress;
}

ul.arrows-list {
	margin: 0px;
	padding: 0px;
	list-style-type: none;

	li {
		margin: 0px;
		padding: 0px 0px 0px 32px;
		margin-bottom: 10px;
		background: url($arrowUrl) no-repeat left center;
		background-size: 16px;

		a {
			color: $link-hover-color;
			&:hover {
				color: $link-color;
			}
		}
	}
}

ul.checkmark-list {
	margin: 0px;
	padding: 0px;
	list-style-type: none;

	li {
		margin: 0px;
		padding: 0px 0px 0px 26px;
		margin-bottom: 8px;
		background: url($checkmarkUrl) no-repeat left center;
		background-size: 13px;
		font-size: 13px;

		a {
			color: $link-hover-color;
			&:hover {
				color: $link-color;
			}
		}

		&.Yes {
			background-image: url("../assets/icons/iconmonstr-check-mark-4-24.png");
		}
		&.No {
			background-image: url("../assets/icons/iconmonstr-minus-4-24.png");
		}
	}

	&.horizontal-list {
		li {
			float: left;
			padding-left: 20px;
			margin-right: 15px;
		}
	}
}

ul.simple-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	li {
		margin-left: 0;
		padding-left: 0;
	}
}

.cms-content {
	@include editor-content-styles();
	p {
		margin-bottom: 3px;
	}
	img {
		max-width: 100%;
	}
}

.with-arrow {
	background: url("../assets/icons/iconmonstr-arrow-right-circle-lined-24.png") no-repeat left center;
	background-size: 15px;
	padding-left: 1.3rem;
}

// d-flex flex-column flex-md-row justify-content-between
.scrolling-wrapper-flexbox {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
}

.scroll-card {
	flex: 0 0 auto;
	margin-right: 15px;

	.force-mobile-layout & {
		width: 100% !important;
		max-width: 100% !important;

		.mss-image-view {
			width: 100% !important;
		}
	}
	@include style-layout-mobile() {
		width: 100% !important;
		max-width: 100% !important;

		.mss-image-view {
			width: 100% !important;
		}
	}

	&:last-of-type {
		margin-right: 0px;
	}
}

.scrolling-wrapper {
	-webkit-overflow-scrolling: touch;
}

.scrolling-wrapper {
	&::-webkit-scrollbar {
		display: none;
	}
}

.last-no-margin {
	&:last-of-type {
		margin-bottom: 0px !important;
	}
}

.z-index-minus-1 {
	z-index: -1;
}

.detail-title {
	color: $gray;
	text-transform: uppercase;
	font-size: 11px;
}

.container.ui-details-list.ui-display-fields {
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-left: 0px !important;
	margin-right: 0px !important;
	.row {
		padding-left: 0px !important;
		padding-right: 0px !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
		[class^="col-"] {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
		padding-top: 15px;
		padding-bottom: 15px;
		border-bottom: solid 1px $border-color;
		font-size: 15px;

		.detail-title {
			@include style-layout-mobile() {
				margin-bottom: 5px;
			}
		}
	}
}

.action-disabled,
.action-disabled * {
	cursor: not-allowed !important;
	pointer-events: all;
}

.no-capitalize {
	text-transform: none !important;
}

.linkify-preview {
	background-color: $gray-100;
	font-family: $font-family-text !important;
	word-break: break-word;

	*,
	div,
	div * {
		font-family: $font-family-text !important;
	}
}

.cms-editable-field {
	cursor: url(/assets/icons/iconmonstr-pencil-9-24.png), pointer !important;
}
