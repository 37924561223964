// TODO: use bootstrap instead e.g mr-xs-1
// layout utils:
@import "app_variables";

@include style-layout-xs() {
	.w-100-xs {
		width: 100% !important;
	}
	.margin-xs-top {
		margin-top: 0.9rem !important;
	}
	.margin-xs-top-2 {
		margin-top: 0.3rem;
	}
	.padding-xs-bottom {
		margin-bottom: 8px !important;
	}
	.mb-xs-2_5 {
		margin-bottom: 0.75rem !important;
	}
	.mt-xs-2_5 {
		margin-top: 0.75rem !important;
	}
	// .mx-1-mobile {
	// 	margin-right: 20px; // TODO: remove, use container
	// 	margin-left: 20px;
	// }

	.px-1-mobile {
		padding-right: 20px !important; // TODO: remove, use container
		padding-left: 20px !important;
	}

	.mb-3-mobile {
		margin-bottom: 1rem !important;
	}

	.mt-4-mobile {
		margin-top: 1.5rem !important;
	}
}

@include style-layout-lg-up() {
	.mb-0-lg {
		margin-bottom: 0px !important;
	}
}

@media (min-width: 1200px) {
	.mb-0-xl {
		margin-bottom: 0px !important;
	}
}

@include style-layout-desktop() {
	.pr-1-desktop {
		padding-right: 0.25rem !important;
	}
	.pl-1-desktop {
		padding-left: 0.25rem !important;
	}
	.mr-auto-desktop {
		margin-right: auto !important;
	}
	.mb-0-desktop {
		margin-bottom: 0rem !important;
	}
	.ml-1-desktop {
		margin-left: 0.25rem !important;
	}
	.ml-2-desktop {
		margin-left: 0.5rem !important;
	}
	.ml-3-desktop {
		margin-left: 1rem !important;
	}
	.mr-3-desktop {
		margin-right: 1rem !important;
	}
	.ml-3-desktop {
		margin-left: 1rem !important;
	}
	.pr-3-desktop {
		padding-right: 1rem !important;
	}
	.pl-3-desktop {
		padding-left: 1rem !important;
	}
	.pr-4-desktop {
		padding-right: 1.5rem !important;
	}
	.pl-4-desktop {
		padding-left: 1.5rem !important;
	}
	.mr-4-desktop {
		margin-right: 1.5rem !important;
	}
	.ml-4-desktop {
		margin-left: 1.5rem !important;
	}
	.mr-5-desktop {
		margin-right: 3rem !important;
	}
	.ml-5-desktop {
		margin-left: 3rem !important;
	}
	.mr-6-desktop {
		margin-right: 4rem !important;
	}
	.ml-6-desktop {
		margin-left: 4rem !important;
	}
	.mb-6-desktop {
		margin-bottom: 4rem !important;
	}
	.mt-6-desktop {
		margin-top: 4rem !important;
	}
	.pb-6-desktop {
		margin-bottom: 4rem !important;
	}
	.mr-8-desktop {
		margin-right: 8rem !important;
	}
	.ml-8-desktop {
		margin-left: 8rem !important;
	}
	.mt-8-desktop {
		margin-top: 8rem !important;
	}
}

@include style-layout-mobile() {
	.w-100-sm {
		width: 100% !important;
	}
	.margin-mobile-top {
		margin-top: 0.9rem;
	}
	.mr-auto-mobile {
		margin-right: auto !important;
	}
	.mr-mobile-1 {
		margin-right: 0.25rem !important;
	}
	.mr-mobile-2 {
		margin-right: 0.5rem !important;
	}
	.ml-0-mobile {
		margin-left: 0px !important;
	}
	.ml-2-mobile {
		margin-left: 0.5rem !important;
	}
	.mb-2-mobile {
		margin-bottom: 0.5rem !important;
	}
	.mt-1-mobile {
		margin-top: 0.25rem !important;
	}
	.mt-2-mobile {
		margin-top: 0.5rem !important;
	}
	.mt-3-mobile {
		margin-top: 1rem !important;
	}
	.mb-3-mobile {
		margin-bottom: 1rem !important;
	}
	.pl-1-mobile {
		padding-left: 0.25rem !important;
	}
	.pl-2-mobile {
		padding-left: 0.5rem !important;
	}
	.pr-2-mobile {
		padding-right: 0.5rem !important;
	}
	.pl-3-mobile {
		padding-left: 1rem !important;
	}
	.mr-minus-15px-mobile {
		margin-right: -15px;
	}
	.mr-minus-23px-mobile {
		margin-right: -23px;
	}
	.mr-minus-8px-mobile {
		margin-right: -8px;
	}
	.pr-15px-mobile {
		padding-right: 15px;
	}
	.pr-23px-mobile {
		padding-right: 23px;
	}
	.mobile-text-center {
		text-align: center;
	}
	.mobile-bg-black {
		background-color: $dark;
	}
}

@include style-layout-xxs() {
	.padding-xxs-bottom {
		margin-bottom: 8px !important;
	}
}

.mt-1-5 {
	margin-top: 0.3rem;
}

.mr-1-5 {
	margin-right: 0.3rem;
}

.mb-2-5 {
	margin-bottom: 0.75rem;
}

.mr-2-5 {
	margin-right: 0.75rem !important;
}

.ml-2-5 {
	margin-left: 0.75rem;
}

.mt-2_5 {
	margin-top: 0.75rem;
}

.mb-3_5 {
	margin-bottom: 1.25rem !important;
}

.pr-3_5 {
	padding-right: 1.25rem !important;
}

.mr-minus-15px {
	margin-right: -15px;
}

.mr-minus-23px {
	margin-right: -23px;
}

.my-4_5 {
	margin-top: 2rem !important;
	margin-bottom: 2rem !important;
}

.mt-4_5 {
	margin-top: 2rem !important;
}

.pt-4_5 {
	padding-top: 2rem !important;
}

.mb-4_5 {
	margin-bottom: 2rem !important;
}

.mb-6 {
	margin-bottom: 4rem !important;
}

.pb-6 {
	padding-bottom: 4rem !important;
}

.pl-4_5 {
	padding-left: 2rem !important;
}

.py-4_5 {
	padding-top: 2rem !important;
	padding-bottom: 2rem !important;
}

// [class^="col-"] {
// 	padding-left: 0px !important;
// 	padding-right: 0px !important;
// }

// @media (min-width: 1000px) {
// 	.container {
// 		max-width: 100%;
// 	}
// }

// @include style-layout-desktop() {
// 	// @media (max-width: 2000px) {
// 	.container {
// 		max-width: 100% !important;
// 	}
// 	// }
// }

// @include style-layout-mobile() {
// 	.container {
// 		max-width: 100% !important;
// 	}
// }

// @include media-breakpoint-up(sm) {
// 	// Styles
//   }

@include style-layout-tablet-mobile() {
	.mr-auto-mobile-tablet {
		margin-right: auto !important;
	}
	.mb-2-mobile-tablet {
		margin-bottom: 0.5rem !important;
	}
}

.site-flex-grid {
	&::after {
		content: "";
		flex: auto;
	}
}
