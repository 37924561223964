@import "app_variables";

input:-webkit-autofill,
//input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
//textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
//select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: $gray;
	-webkit-box-shadow: 0 0 0px 1000px $white inset !important;
	transition: background-color 5000s ease-in-out 0s;
}

button {
	text-transform: uppercase;
	font-weight: 500;
	cursor: pointer;
	&.btn {
		padding: 13px 25px;
		cursor: pointer;
	}
	@include style-layout-desktop() {
		&.btn-narrow {
			padding: 7px 25px;
		}
	}

	&.btn.btn-light {
		background-color: transparent !important;
		color: $light;
		&:hover {
			color: $gray;
			border-color: $gray;
		}
	}

	&.btn.btn-primary {
		min-width: 150px;
	}

	&.btn-warning {
		color: #ffffff;
	}

	&.btn-link {
		border: solid 1px $primary;
		&:hover {
			border-color: $gray;
		}
	}

	&.btn-default {
		border: solid 1px $gray;
		&:hover {
			color: $primary;
			border-color: $primary;
		}
	}

	&.btn.btn-small {
		padding: 5px 10px !important;
		font-size: 11px !important;
	}

	&.btn.btn-lg {
		border-radius: $border-radius !important;
	}

	// &.btn-secondary {
	// 	background-color: $black;
	// 	color: #ffffff;
	// }

	// &.btn-primary,
	// &.btn-info,
	// &.Button__button___vS7Mv {
	// 	&:not(.no-shadow) {
	// 		@extend .shadow;
	// 	}
	// }
	// &.btn-link,
	// &.btn-primary,
	// &.btn-info {
	// 	font-size: $font-size-md;
	// }

	// &.btn-processing {
	// 	cursor: progress !important;
	// 	@include showLoader();
	// }

	&.btn-loading {
		cursor: progress !important;
		@include showLoader();
	}

	// &.btn-link.blue-link-btn {
	// 	border: solid 1px $primary;
	// 	&:hover {
	// 		color: $link-hover-color;
	// 		border-color: $link-hover-color;
	// 	}
	// 	&:disabled {
	// 		color: $primary-light !important;
	// 		cursor: not-allowed !important;
	// 		pointer-events: unset;

	// 		&.text-white {
	// 			color: white !important;
	// 		}
	// 	}
	// }

	&.btn-processing {
		@include progress-bar-background();
		height: auto;
		width: auto;

		&.btn-primary {
			// background-image: repeating-linear-gradient(
			// 	-45deg,
			// 	$primary-lightest,
			// 	$primary-light 11px,
			// 	$primary 10px,
			// 	$primary 20px
			// );

			// background-image: repeating-linear-gradient(-45deg, $red, $primary 11px, $orange 10px, $primary 20px);
			// background-image: repeating-linear-gradient(-45deg, $red, $primary 20px, $orange 20px, $primary 20px);
			// background-image: repeating-linear-gradient(-45deg, $primary, $orange 10px, $primary 20px);

			background-image: linear-gradient(to bottom, $primary, $secondary, $primary);
			background-size: 200px 200px;
			animation: move-down 0.8s ease infinite;
		}

		&.btn-uploading-text {
			* {
				display: none !important;
			}
			&:after {
				content: "Uploading...";
				width: 100%;
				display: inline-block;
				text-align: center;
			}
			&.text-xs:after {
				font-size: $font-size-xs !important;
			}
		}
	}

	&:disabled {
		cursor: not-allowed !important;
		pointer-events: unset;
	}

	&.icon-btn-outlined {
		background-color: rgba(0, 0, 0, 0.06);
		&:hover {
			background-color: rgba(0, 0, 0, 0.15); // 0.4 original material ui value
		}
	}
	&.icon-btn-outlined-light {
		background-color: rgba(255, 255, 255, 0.1);
		&:hover {
			background-color: rgba(255, 255, 255, 0.15); // 0.4 original material ui value
		}
	}
}

// .MuiDialogActions-root {
// 	margin-bottom: 13px;
// 	.MuiButton-textPrimary {
// 		// @extend .btn-link;
// 		padding-right: 22px;
// 		padding-left: 22px;
// 	}
// 	.MuiButton-textPrimary + .MuiButton-textPrimary {
// 		@extend .btn-primary;
// 		margin-right: 15px;
// 		&.Mui-disabled {
// 			cursor: not-allowed !important;
// 			pointer-events: unset;
// 		}
// 		display: none !important;
// 	}
// }

.form-row {
	margin-right: 0px;
	margin-left: 0px;

	&.ui-date-picker {
		.MuiFormControl-root {
			width: 100% !important;
		}
	}

	label,
	label.MuiFormLabel-root {
		text-transform: uppercase;
	}

	p.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error {
		padding-left: 0px;
		margin-left: 0px;
	}
}

.MuiPickersDay-daySelected {
	color: white !important;
}

.MuiPickersDay-hidden {
	// disabled days in date picker
	opacity: 0.35 !important;
}

// // remove red error label
// .MuiFormControl-root.MuiTextField-root {
// 	.MuiInputLabel-formControl.Mui-error {
// 		color: rgba(0, 0, 0, 0.54);
// 		&.Mui-focused {
// 			color: $orange;
// 		}
// 	}
// // }

.ui-combobox {
	.MuiFormControl-root {
		width: 100%;

		button.MuiAutocomplete-popupIndicator {
			display: inline-block;
			margin-top: -3px;
			background-color: #ffffff;
		}
	}
}

// .MuiAutocomplete-paper,
// .MuiAutocomplete-listbox,
// .MuiAutocomplete-paper,
.MuiAutocomplete-option
// .MuiAutocomplete-tag
{
	min-height: 36px !important;
	&,
	* {
		font-size: $font-size-md-2 !important;
	}
}

.ui-icon-btn-bordered {
	border: solid 1px $primary-lightest !important;
}

.ui-icon-btn-bordered-hover {
	border: solid 1px white !important;
	&:hover {
		border: solid 1px $primary-lightest !important;
	}
}

button.ui-icon-btn-floating {
	border: solid 1px $border-color !important;
	padding: 8px !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	background-color: $light;
	svg {
		width: 0.85em !important;
		height: 0.85em !important;
	}
}

.form-row {
	.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
		fieldset.MuiOutlinedInput-notchedOutline {
			border-color: $border-color !important;
			border-bottom: 1px solid $gray !important;
			transition: border-color 0.2s ease-in-out;
		}

		&.Mui-focused {
			fieldset.MuiOutlinedInput-notchedOutline {
				border-color: $gray !important;
				border-width: 1px !important;
			}
		}
		&:hover {
			fieldset.MuiOutlinedInput-notchedOutline {
				border-color: $gray-500 !important;
				border-bottom: 1px solid $gray !important;
			}
		}

		&.Mui-error {
			fieldset.MuiOutlinedInput-notchedOutline {
				// border-color: $danger !important;
				border-bottom: 1px solid $danger !important;
			}
		}
	}
}

.MuiButtonBase-root.MuiListItem-root.MuiListItem-button {
	// color: red !important; // var(--color-contrast-text) !important;
}

.MuiPopover-root:not(.ui-menu-btn-popover) {
	.MuiMenuItem-root:hover {
		background-color: rgba(238, 238, 238, 0.6) !important; //var(--color-contrast-bg-hover) !important;
	}
}

.MuiInputBase-inputMultiline {
	line-height: 1.6 !important;
}

.border-bottom-error {
	border-bottom: solid 1px $material-error;
}
