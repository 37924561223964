.ui-address-container {
	position: relative;
	overflow: visible;
	z-index: 0;

	.address-display.gray-link.inner-link,
	.address-display.gray-link.inner-link * {
		cursor: url(/assets/icons/iconmonstr-map-8-24.png), pointer !important;
	}
}

.address-hover-image {
	z-index: 9999;
	position: absolute;
	width: 380px;
	max-width: 90%;
	min-height: 184px;
	@media (max-width: 380px) {
		min-height: 174px;
	}

	.address-image-loader {
		position: absolute;
		z-index: 0;
		top: 40%;
		left: 44%;
	}

	img {
		position: relative;
		z-index: 1;
	}
}
